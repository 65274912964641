import '@/styles/common.scss';

import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueGtm from '@gtm-support/vue2-gtm';
import { library } from '@fortawesome/fontawesome-svg-core/index.js';
import { faUser, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from '@/app.vue';
import router from '@/router/index.js';
import guard from '@/api/guard.js';
import { PiniaVuePlugin, createPinia } from 'pinia';
import { useStore } from '@/stores/index.js';

library.add(faUser);
library.add(faHome);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueGtm, {
  id: 'GTM-P5S72R2',
  vueRouter: router,
});

// hasQueryParams to keep query params when route change
const hasQueryParams = (route) => !!Object.keys(route.query).length;

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Plumsail Account';
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    // Keep only params that we need not change between route change
    const { ReturnUrl, returnUrl, email } = from.query;
    const { name } = to;

    next({
      name,
      query: {
        returnUrl: returnUrl || '',
        ReturnUrl: ReturnUrl || '',
        email,
      },
    });
  } else {
    next();
  }
});

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
const store = useStore(pinia);

router.beforeEach(guard(store));

new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount('#app');

import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    name: 'Auth',
    path: '/',
    component: () => import('@/views/auth.vue'),
    redirect: { name: 'Login' },
    children: [
      {
        name: 'Account',
        path: '/account',
        redirect: { name: 'Login' },
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            name: 'Login',
            path: 'login',
            component: () => import('@/views/login.vue'),
            meta: {
              title: 'Log in - Plumsail Account',
            },
          },
          {
            name: 'Logout',
            path: 'logout',
            component: () => import('@/views/login.vue'),
            meta: {
              title: 'Log out - Plumsail Account',
            },
          },
          {
            name: 'InviteLogin',
            path: 'invite/:invitationHash',
            component: () => import('@/views/login.vue'),
            props: true,
            meta: {
              title: 'Log in - Plumsail Account',
            },
          },
          {
            name: 'Register',
            path: 'register',
            props: true,
            component: () => import('@/views/register.vue'),
            meta: {
              title: 'Sign up - Plumsail Account',
            },
          },
          {
            name: 'InviteRegister',
            path: 'register/:invitationHash',
            props: true,
            component: () => import('@/views/register.vue'),
            meta: {
              title: 'Sign up - Plumsail Account',
            },
          },
          {
            name: 'ChangePassword',
            path: 'change-password',
            component: () => import('@/views/change-password.vue'),
            meta: {
              title: 'Change password - Plumsail Account',
            },
          },
          {
            name: 'SelectTeam',
            path: 'select-team',
            component: () => import('@/views/select-team.vue'),
            props: true,
            meta: {
              title: 'Select team - Plumsail Account',
            },
          },
          {
            name: 'SelectRegion',
            path: 'select-region',
            component: () => import('@/views/external-registration-completion.vue'),
            props: true,
            meta: {
              title: 'Select region - Plumsail Account',
            },
          },
        ],
      },
    ],
  },
  {
    path: '*',
    redirect: { name: 'Login' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

import axios from 'axios';
import { defineStore } from 'pinia';
import Cookie from 'js-cookie';
import { getReturnUrl } from '@/utils/index.js';

const authEndpoint = `${process.env.VUE_APP_AuthServer}api/account/`;

axios.interceptors.response.use((response) => response, (error) => {
  if (error.response.status >= 500) {
    // eslint-disable-next-line
    error.response.data = 'Something went wrong. Please contact support@plumsail.com';
  }

  return Promise.reject(error);
});

export const useStore = defineStore('store', {
  state: () => ({
    authLoading: false,
    userTryLoading: false,
    availableTeams: null,
    invitingTeamId: null,
  }),

  actions: {
    async loginAccount({
      email, password, returnUrl, invitationHash,
    }) {
      try {
        this.authLoading = true;

        const { data } = await axios.post(`${authEndpoint}login`, {
          email,
          password,
          returnUrl,
          invitationHash,
        }, { withCredentials: true });

        this.setSelectedTeamCookie();

        return (window.location.href = data.url);
      } catch (error) {
        return { data: null, error: error.response.data };
      } finally {
        this.authLoading = false;
      }
    },

    async login2Fa(payload) {
      try {
        this.authLoading = true;

        const { data } = await axios.post(`${authEndpoint}login/2fa`, payload, { withCredentials: true });

        return (window.location.href = data.url || payload.returnUrl);
      } catch (error) {
        return { data: null, error: error.response.data };
      } finally {
        this.authLoading = false;
      }
    },
    async selectTeam({ teamId, returnUrl }) {
      try {
        this.authLoading = true;

        const { data } = await axios.post(`${authEndpoint}teams/select/${teamId}`,
          { returnUrl }, { withCredentials: true });
        return (window.location.href = data.url);
      } catch (error) {
        return { data: null, error: error.response.data };
      } finally {
        this.authLoading = false;
      }
    },
    async registerAccount({ email, password, invitationHash, region }) {
      try {
        this.authLoading = true;

        const { data } = await axios.post(`${authEndpoint}register`, {
          email,
          password,
          invitationHash: invitationHash || null,
          region,
        }, { withCredentials: true });

        this.setSelectedTeamCookie();
        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      } finally {
        this.authLoading = false;
      }
    },
    async verifyCode({
      email,
      code,
      returnUrl,
      redirect,
      type,
    }) {
      try {
        this.authLoading = true;

        const { data } = await axios.post(`${authEndpoint}users/manage/verify/code`, {
          email,
          code,
          returnUrl,
          type,
        }, { withCredentials: true });

        return redirect ? (window.location.href = data.url) : { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      } finally {
        this.authLoading = false;
      }
    },
    async resendEmail({ email }) {
      try {
        this.authLoading = true;

        const { data } = await axios.post(`${authEndpoint}users/manage/resend/code`, { email });

        return { data, error: null };
      } catch (error) {
        if (error.response.status === 429) {
          return { data: null, error: 'You\'ve made too many requests for 2FA codes. Please, try again after some time.' };
        }

        return { data: null, error: error.response.data };
      } finally {
        this.authLoading = false;
      }
    },
    async restorePassword({ email }) {
      try {
        this.authLoading = true;

        const { data } = await axios.post(`${authEndpoint}users/manage/password/restore`, { email });

        return { data, error: null };
      } catch (error) {
        if (error.response.status === 429) {
          return { data: null, error: 'You\'ve made too many reset password requests. Please, try again after some time.' };
        }

        return { data: null, error: error.response.data };
      } finally {
        this.authLoading = false;
      }
    },
    async restorePasswordConfirm({
      email, password, code, type,
    }) {
      try {
        this.authLoading = true;

        const { data } = await axios.post(`${authEndpoint}users/manage/password/restore/confirm`, {
          email,
          password,
          confirmCode: code,
          type,
        });

        return (window.location.href = data.url);
      } catch (error) {
        return { data: null, error: error.response.data };
      } finally {
        this.authLoading = false;
      }
    },
    async externalRedirect({ scheme, returnUrl, invitationHash }) {
      try {
        this.authLoading = true;

        const { data } = await axios.post(`${authEndpoint}challenge`, {
          scheme,
          returnUrl,
          invitationHash,
        }, { withCredentials: false });

        this.setSelectedTeamCookie();

        return (window.location.href = data.RedirectUri);
      } catch (error) {
        return { data: null, error: error.response.data };
      } finally {
        this.authLoading = false;
      }
    },
    async directRedirect({ next, to }) {
      try {
        const { data } = await axios.get(`${authEndpoint}redirect/?returnUrl=${encodeURIComponent(getReturnUrl(to.query) || '')}`, {
          withCredentials: true,
        });

        return (window.location.href = data.url);
      } catch (error) {
        return next();
      } finally {
        this.userTryLoading = true;
      }
    },
    async loadAvailableTeams() {
      try {
        const { data } = await axios.get(`${authEndpoint}teams`);

        this.availableTeams = data;

        return { data: null, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      } finally {
        this.userTryLoading = true;
      }
    },
    async loadInvitingTeamId(hash) {
      if (hash && !this.invitingTeamId) {
        const resp = await axios.get(`${authEndpoint}teams/id/by-hash/${hash}`);
        if (resp.data) {
          this.invitingTeamId = resp.data;
        }
      }
    },
    setSelectedTeamCookie() {
      if (this.invitingTeamId) {
        Cookie.set('SelectedTeam', this.invitingTeamId, { expires: 1 / 1440, path: '/', domain: 'plumsail.com' });
      }
    },
    async confirmExternalLogin(code) {
      try {
        this.authLoading = true;
        const { data } = await axios.get(`${authEndpoint}external-login/confirm/?code=${code}`, { withCredentials: true });

        return (window.location.href = data.url);
      } catch (error) {
        return { data: null, error: error.response.data };
      } finally {
        this.authLoading = false;
      }
    },
    async completeExternalRegistration(region) {
      try {
        this.authLoading = true;
        const { data } = await axios.get(`${authEndpoint}external-signup/${region}`, { withCredentials: true });

        return (window.location.href = data.url);
      } catch (error) {
        return { data: null, error: error.response.data };
      } finally {
        this.authLoading = false;
      }
    },
    async completeRegistration({
      email,
      code,
      returnUrl,
      redirect,
      type,
      region, 
    }) {
      try {
        this.authLoading = true;
  
        const { data } = await axios.post(`${authEndpoint}register/complete/${region}`, {
          email,
          code,
          returnUrl,
          type,
        }, { withCredentials: true });
  
        return redirect ? (window.location.href = data.url) : { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      } finally {
        this.authLoading = false;
      }
    },
  },
});
